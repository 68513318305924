import React from "react";
import {Link} from "react-router-dom";

const ErrPage = ()=> {
    return(
        <>
        <div className="row" style={{marginTop:"10em"}}>
            <div className = "col s12 m8 offset-m2">
            <h5 className="green-text">Error 404!</h5>
            <p>
                The page you searched is not found. May be you have clicked a broken link.
            </p>
            <button className="btn btn-flat teal"><Link to="/" className="white-text">Back to home</Link></button>
        </div></div>
    </>
    );
    
}

export default ErrPage;