import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import M from 'materialize-css';
import { LocalStorageKey } from '../conf/localkeys';
import { fileUpload} from '../utils/ui/file.upload';
import Preloader from '../utils/ui/Preloader';
import { updateItemById, updateItemImage } from '../db/db.item.save';

const ItemEdit = () => {
  const params = useParams();
  const items = JSON.parse(localStorage.getItem(LocalStorageKey.CURRENTITEM));
  const [item, setItem] = useState({});


  const loadItem = () => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (params.itemId === item.id) {
        setItem(item);
      }
    }
  }

  useEffect(() => {
    loadItem();
  }, []);

  return (
    <>
      {Object.keys(item).length > 0 ? <> <ItemUpdate item={item}/> <Imagecard item={item}/> </> : <Preloader/>}
    </>
  )
}

const ItemUpdate = ({item}) => {
  const navigate                = useNavigate();
  const catList                 = JSON.parse(localStorage.getItem(LocalStorageKey.ITEMCATEGORY));
  const [itemName, setItemName]           = useState(item.itemName);
  const [itemDesc, setItemDesc]           = useState(item.itemDescription);
  const [itemCat, setItemCat]             = useState(item.categoryId);
  const [itemMrp, setItemMrp]             = useState(item.mrp);
  const [itemSalePrice, setItemSalePrice] = useState(item.salePrice);
  const [btnd, setBtnd]                   = useState(false);

  const btnUpdate = async() =>{
    if(itemName===""){
      M.toast({html: 'Item name are required!', classes: 'rounded'});
      return;
    }
    if(itemCat===""){
      M.toast({html: 'Item category are required!', classes: 'rounded'});
      return;
    }
    if(itemMrp===""){
      M.toast({html: 'MRP are required!', classes: 'rounded'});
      return;
    }
    if(itemSalePrice===""){
      M.toast({html: 'Sale price are required!', classes: 'rounded'});
      return;
    }
    //setBtnd(true);

    item.itemName        = itemName;
    item.searchKey       = itemName.replace(/ /g, "").toLowerCase();
    item.itemDescription = itemDesc;
    item.categoryId      = itemCat;
    item.mrp             = itemMrp;
    item.salePrice       = itemSalePrice;
    item.updateOn        = Date.now();

    for(let i = 0; i < catList.length; i++){
      if(catList[i].id===itemCat){
        item.categoryName = catList[i].categoryName;
      }
    }
  await updateItemById(item.id, item);
  localStorage.removeItem(LocalStorageKey.CURRENTITEM);
  navigate('/item/list', { replace: true });      
  }

  
useEffect(() => {
    // selectCategory("catOption", catList, item.categoryId);
    M.AutoInit();
  }, []);
  
  return(<>
      <div className='row'>
        <div className='col s12 m6 offset-m3'>
          <div className='card'>
            <div className='card-content'>
              <h4 className='headline1'>Edit Item</h4>
              <div className='row'>
                <div className='input-field col s12'>
                  <input type='text' value={itemName} onChange={(e) => setItemName(e.target.value)} />
                  <label className='active'>Item Name</label>
                </div>
                <div className='input-field col s6'>
                  <input type='text' value={itemDesc} onChange={(e) => setItemDesc(e.target.value)} />
                  <label className='active'>Description</label>
                </div>
                <div className="input-field col s6">
                  <select id='catOption' value={itemCat} onChange={(e) => setItemCat(e.target.value)}>
                    <option value="" >Choose your option</option>
                  </select>
                </div>
                <div className='input-field col s6'>
                  <input type='text' value={itemMrp} onChange={(e) => setItemMrp(e.target.value)} />
                  <label className='active'>MRP</label>
                </div>
                <div className='input-field col s6'>
                  <input type='text' value={itemSalePrice} onChange={(e) => setItemSalePrice(e.target.value)} />
                  <label className='active'>Sale Price</label>
                </div>
                <div className='col s12 center-align'>
                  <button className='btn green btn-flat white-text btn-small' onClick={()=> btnUpdate()} disabled={btnd}>Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </>);
}

const Imagecard = ({ item }) => {
  const navigate                            = useNavigate();
  const [uploadableFile, setUploadableFile] = useState(null);
  const [imagePreview, setImagePreview]     = useState(item.image);
  
  const loadImg = (e) =>{
    setImagePreview   (URL.createObjectURL(e.target.files[0]));
    setUploadableFile (e.target.files[0]);
  }

  const uploadFile = async() =>{
    const link = await fileUpload(uploadableFile, "images/products/"+item.id+".jpg");
    await updateItemImage(item.id, link);
    localStorage.removeItem(LocalStorageKey.CURRENTITEM);
    navigate('/item/list', { replace: true });
  }

  return (
    <>
      <div className='row'>
        <div className='col s12 m6 offset-m3'>
          <div className='card-panel'>
            <div className='center-align' style={{ height: "250px" }}>
              <img src={imagePreview} height="100%" />
            </div>
            <div className='row'>
              <div className='col s12 file-field input-field'>
                <div className="btn">
                  <span>File</span>
                  <input type="file" onChange={e=>loadImg(e)}/>
                </div>
                <div className="file-path-wrapper">
                  <input className="file-path validate" type="text" />
                </div>
              </div>
              <div className='col s12 center-align'>
                <button className='btn blue' onClick={e=>uploadFile()}>Upload</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemEdit