import { fsdb, docList } from "../conf/fire";

export const getUserByPhone = async (phoneNo) => {
    const db = fsdb();
    let user = {};
    const snap = await db.collection(docList.USERS).where("phone", "==", phoneNo).limit(1).get();
    snap.forEach(doc => {
        user    = doc.data();
        user.id = doc.id;
    });

    return user;
}