import React from 'react'
import { Link } from 'react-router-dom';

const ItemCard = ({item}) => {
 const imageErrorSrc = "https://firebasestorage.googleapis.com/v0/b/rapidel-28a1d.appspot.com/o/images%2Fproducts%2FrSyzxB0nLmWXYaxEzMhs.png?alt=media&token=76c173f3-66e6-40f3-a24e-5b022e2b33a6"; 
  return (
    <>
        <div className="col s6 m2">
             <div className="card">
                <div className="card-content" style={{paddingBottom:"0px"}}>
                    <div className="row">
                        <div className="col s12 card-image">
                            <img src={item.image.length === 0 ? imageErrorSrc : item.image} className="responsive-img"/>
                        </div>
                        <div className='col s12'>
                                <span className="black-text truncate"><strong>{item.itemName}</strong></span>
                                {item.categoryName.length === 0 ? "Uncategorized" : item.categoryName}
                        </div>
                    </div>
                </div>
                <div className="card-action grey lighten-4">
                    <Link className="black-text" to={"/item/"+item.id+"/v"}>VIEW</Link>
                    <Link className="black-text" to={"/item/"+item.id+"/save"}>edit</Link>
                </div>
             </div>
        </div>
    </>
  )
}

export default ItemCard