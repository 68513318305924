import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

export const fsdb = ()=>{
    return firebase.firestore();
}

export const getStoraze = () => {
    return firebase.storage();
}

export const parseSnap = (snap) =>{
    const list      = [];
    if(snap.length == 0){
        return [];
    }
    snap.forEach(docs =>{
        let doc = docs.data(); doc.id = docs.id;
        list.push(doc);
    });
    return list;
}

export const updateFs = async (tbl, id, data) =>{
    const db    = fsdb();
    await db.collection(tbl).doc(id).update(data);
    return id;
}

export const add2Fs = async (tbl, data) =>{
    const db    = fsdb();
    const ref   = await db.collection(tbl).add(data);
    return ref.id;
}

export const getModelById = async (tbl, id) =>{
    const db = fsdb();
    const ref = await db.collection(tbl).doc(id).get();
    if(ref.exists){
        let model = ref.data(); model.id = id;
        return model;
    }
    return {};
}



export const docList ={
    USERS : "USERS",
    USER_ADDRESS : "USERS_ADDRESS",
    STORES : "STORES",
    STORE_ITEMS : "STORE_ITEMS",
    ORDER_ITEMS : "ORDER_ITEMS",
    ORDER_MASTER : "ORDER_MASTER",
    ITEM_CATEGORY : "ITEM_CATEGORY"
};