export const getStrDate = (millis) =>{
    let date    = new Date(millis);
    let dd      = date.getDate();
    let dat     = dd < 10 ? "0"+dd : dd;
    let mm      = date.getMonth()+1; //January is 0!
    let MM      = mm < 10 ? "0"+mm : mm;
    let yyyy    = date.getFullYear();
    let strDate = dat + "-" + MM + "-" + yyyy;
    return strDate;
}

export const hasTimeGone = (millis) => {
    let date = Date.now();
    return date > millis ? true : false;
}

export const millis ={
       oneDay : 86400000,
       sevenDays : 604800000,
       thirtyDays : 2592000000
};