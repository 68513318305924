import React from 'react'

const Preloader = () => {
    return (
        <>
            <div class="progress">
                <div class="indeterminate"></div>
            </div>
        </>
    )
}

export default Preloader