const usertypeReducer = (state = false, action) => {
    switch (action.type) {
        case "STORE_ID_YES":
            return true;
        case "STORE_ID_NO":
            return false;
    }
    return state;
}

export default usertypeReducer;