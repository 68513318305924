import { docList, fsdb } from "../conf/fire"

export const getLatestOrders = async (time, storeId) =>{
      const orders = [];
      const db = fsdb();
      const snap = await db.collection(docList.ORDER_MASTER)
                   .where("storeId", "==", storeId)
                   .where("placedOn", "<", time)
                   .orderBy("placedOn", "desc")
                   .limit(15).get();
      
      snap.forEach(docs => {
        let ordr = docs.data();
        ordr.id = docs.id;
        orders.push(ordr);
      });

      return orders;
}


export const getOrderDetail = async (id) =>{
      const db = fsdb();
      const ref = await db.collection(docList.ORDER_MASTER).doc(id).get();
        let order = ref.data();
        order.id = ref.id;
        return order;
}