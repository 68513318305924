import  loggedReducer  from "./logged";
import  cartItems  from "./cartitem";
import orderUpdateReducer from "./orderupdate";
import usertypeReducer from "./usertype";
import  {combineReducers}  from "redux";

const reduxList = combineReducers({
    cartItems       : cartItems,
    isLogged        : loggedReducer,
    isupdateorder   : orderUpdateReducer,
    isUserType      : usertypeReducer 
})

export default reduxList;