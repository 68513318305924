import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getItemByStoreId } from '../db/db.item.load';
import { LocalStorageKey } from '../conf/localkeys';
import ItemCard from './ItemCard';
import Preloader from '../utils/ui/Preloader';

const ItemList = () => {
    const store = JSON.parse(localStorage.getItem(LocalStorageKey.CURRENTSTORE));
    const lastMillis = new Date().getTime();
    const [list, setList] = useState(JSON.parse(localStorage.getItem(LocalStorageKey.CURRENTITEM)));

    const loadItem = async () => {
        const snap = await getItemByStoreId(store.id, lastMillis);
        if (snap.length > 0) {
            localStorage.setItem(LocalStorageKey.CURRENTITEM, JSON.stringify(snap));
            setList(snap);
        }
    }

    useEffect(() => {
        if (!list) {
            loadItem();
        }
    }, [])

    return (
        !list ? <Preloader /> :
        <>
            <div className="row" style={{ marginTop: "2em" }}>
                <div className='col s10'>
                    <h5>List Of Items</h5>
                </div>
                <div className='col s2'>
                    <Link className='btn btn-flat green white-text right' to="/item/new/save">Add Item</Link>
                </div>
            </div>
            <div className='row'>
                {list.map(itm => ( <ItemCard key={itm.id} item={itm} />))}
            </div>
        </>
    )
}
export default ItemList;