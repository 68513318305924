
export const LocalStorageKey = {
    ADDRESSLIST : "addressList",
    USERADDRESS : "userAddr",
    USER        : "vipanim.user.seller",
    CURRENTSTORE: "vipnim.seller.store",
    CURRENTITEM : "vipnim.seller.items",
    ITEMCATEGORY: "vipnim.seller.items.category",
    CARTITEMS   : "cartItems",
    AUTH        : "auth",
    STOREID     : "storeid",
    STOREITEMS  : "storeitems",
    STOREORDER  : "storeorder",
    ORDERMASTER : "oredrmaster",
    ORDERITEMS  : "orderitems",    
    ORDERITEMID : "OrderItemId",
    EDITITEM    : "EditItem",
}




export const SessionKey = {
    REDIRECT  : "redirect",
    SHOW_HIDE : "show_hide"
}


