import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { LocalStorageKey } from '../conf/localkeys';


const Header = () => {
    const logged = useSelector(state => state.isLogged);
    const currStore = JSON.parse(localStorage.getItem(LocalStorageKey.CURRENTSTORE));

    return (
        <>
            <nav>
                <div className="nav-wrapper teal darken-4">
                    <a href="/" className="brand-logo"><i className="material-icons"></i>VIPANIM</a>
                    <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                    <ul className="right">
                        <li className="active"><a href="/">Home</a></li>
                        <li className="active"><a href="/order/a">Orders</a></li>
                        <li className="active"><a href="/item/list">Items</a></li>
                        <li className="active"><a href="/user/logout">Sign Out</a></li>
                    </ul>
                    <ul className="sidenav" id="mobile-demo">
                        <li className="active"><a href="/">Home</a></li>
                        <li className="active"><a href="/order/a">Orders</a></li>
                        <li className="active"><a href="/item/list">Items</a></li>
                        <li className="active"><a href="/user/logout">Sign Out</a></li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Header;