const orderUpdateReducer = (state = false, action) => {
    switch (action.type) {
        case "UPDATE_DONE":
            return true;
        case "UPDATE_FAILD":
            return false;
    }

    return state;
}

export default orderUpdateReducer;