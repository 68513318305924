import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LocalStorageKey } from '../conf/localkeys'
import { useDispatch } from 'react-redux';

const SignOut = () => {
    const navigate = useNavigate();
    const dispatch  = useDispatch(); 


    const logOut = () =>{
        localStorage.removeItem(LocalStorageKey.USER);
        localStorage.removeItem(LocalStorageKey.CURRENTSTORE);
        dispatch({type:"SIGN_OUT"});
        navigate("/");
    }


  return (
    <>
        <div className='row' style={{marginTop:"5em"}}>
            <div className='col s12 m6 offset-m3'>
                <div className='card' style={{paddingTop:"2em", paddingBottom:"2em"}}>
                    <div className='card-content'>                    
                        <h4 className='headline1 center'>
                            Sign Out ?
                        </h4>
                        <p align="center">
                            <Link className='btn btn-flat green white-text' style={{marginRight:"0.5em"}} to="/">Back to Home</Link>
                            <button className='btn btn-flat red white-text' style={{marginLeft:"0.5em"}} onClick={()=>logOut()}>Log Out</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SignOut