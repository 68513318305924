export const storeItems = {
        storeId              : "",
        storeName            : "",
        
        itemCode             : "",
        itemName             : "",
        searchKey            : "",
        itemPacking          : "",
        itemDescription      : "",
        itemVarient          : "",
        itemAttribute        : "",
        image                : "https://firebasestorage.googleapis.com/v0/b/rapidel-28a1d.appspot.com/o/images%2Fproducts%2FrSyzxB0nLmWXYaxEzMhs.png?alt=media&token=76c173f3-66e6-40f3-a24e-5b022e2b33a6",
        qnty                 : "",
        MOU                  : "Nos",

        mrp                  : 0,
        salePrice            : 0,
        discount             : 0,
        shippingCharge       : 0,

        isReturnAllowed      : 0,
        isActive             : 1,
        
        categoryId           : "",
        categoryName         : "",
        locality             : "",
        updateOn             : 0
}