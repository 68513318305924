import "firebase/compat/firestore";
import "firebase/compat/storage";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const fileUpload = async (file, filename) => {
  const storage = getStorage();
  const metadata = {
    contentType: 'image/jpeg'
  };

  const storageRef = ref(storage, filename);
  const snap = await uploadBytes(storageRef, file, metadata);
  const link = await getDownloadURL(snap.ref);
  return link;
}
