import React, { useState, useEffect } from 'react';
import { useNavigate, redirect  } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import {useSelector, useDispatch} from "react-redux";
import {LocalStorageKey} from "../conf/localkeys";
import {getUserByPhone} from "../db/db.user.load";

const SignIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState("");
    const [msgColor, setMsgColor] = useState("black-text");
    
    const login = async () =>{
        const user = await getUserByPhone(username);
        if(Object.keys(user).length === 0){
            setMsg("Phone number not found"); setMsgColor("red-text");
            return;
        }

        if(user.password === password){
            if(user.storeId === ""){
                setMsg("Store Not found"); setMsgColor("red-text");
                return;
            }else{
                setMsg("Signing in..."); setMsgColor("black-text");
                localStorage.setItem(LocalStorageKey.USER, JSON.stringify(user));
                dispatch({type:"SIGN_IN"});
                navigate("/");
            }
        }else{
            setMsg("Incorrect password"); setMsgColor("red-text");
        }
    }

    return (
        <>
            <div className="row" style={{marginTop: "100px"}}>
                <div className="col s12 m4 l4 offset-l4 offset-m4 z-depth-4" style={{background: "white", padding: "30px"}}>
                        <div className="row">
                            <div className ="col s12">
                                <h3 className ="stylish_headline">Sign In</h3>
                                <p className={msgColor}>{msg}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col input-field s12">
                                <input type="email" name="email" id="email" value={username} onChange = {(e)=>setUsername(e.target.value)} placeholder="Email Or Phone" />
                                <label htmlFor="email" className="active">Email Or Phone</label>
                            </div>

                            <div className="col input-field s12">
                                <input type="password" name="password" id="password" value={password} onChange = {(e)=>setPassword(e.target.value)} placeholder="Password" />
                                <label htmlFor="password"  className="active">Password</label>
                            </div>

                            <div className="col s12">
                                <button id="login_btn" className="btn btn-flat teal white-text" onClick = {login}>
                                    Sign in
                                </button>
                            </div>
                        </div>
                </div>
            </div>
        </>
    );
}




export default SignIn;