export const getOrderStatus = (num) =>{
    switch(num){
        case -1 : return "Created";
        case 0  : return "Arrived";
        case 1  : return "Pending";
        case 10 : return "Accepted";
        case 11 : return "Rejected";
        case 90 : return "Processed";
        case 100: return "Delivered";
    }

    return "Unknown";
}

