import { fsdb, docList, parseSnap } from "../conf/fire";
import { getLocalList, localKeys, setLocal } from "../utils/xtra/localstore";

export const getCatList = async() =>{
    const db        = fsdb();
    const snap      = await db.collection(docList.ITEM_CATEGORY).limit(30).get();
    return parseSnap(snap);
}

export const getLocalCategories = async () =>{
    let catList = await getLocalList(localKeys.VIPANIM_ITEM_CATEGORY);
    if(catList.length === 0){
        catList = await getCatList();
        setLocal(localKeys.VIPANIM_ITEM_CATEGORY, catList);
    }

    return catList;
}


export const getCategory = async (id) =>{
    const db    = fsdb();
    const ref   = await db.collection(docList.ITEM_CATEGORY).doc(id).get();

    if(ref.exists){
        let doc = ref.data(); doc.id = ref.id;
        return doc;
    }

    return {};
}