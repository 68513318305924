import { fsdb, docList } from "../conf/fire";


export const getStoreDetail = async (storeId) =>{
	let store = {};
	const db = fsdb();
	const ref = await db.collection(docList.STORES).doc(storeId).get();
    if(ref.exists){
		store 		= ref.data();
		store.id 	= ref.id;
		return store;
	} 
	return store;
}

export const updateLastActive = (storeId) =>{
	// firebase.firestore().collection(docList.STORES).doc(storeId).update({
	// 	lastActive : (new Date()).getTime()
	// });
}

