export const firebaseConfig = {
    apiKey: "AIzaSyBEFG5DXKSdy95uuZ57kzz4vi9ECPwBko8",
    authDomain: "rapidel-28a1d.firebaseapp.com",
    databaseURL: "https://rapidel-28a1d.firebaseio.com",
    projectId: "rapidel-28a1d",
    storageBucket: "rapidel-28a1d.appspot.com",
    messagingSenderId: "4189177479",
    appId: "1:4189177479:web:96c467cc6432b2e8e95a22",
    measurementId: "G-D49P1FEDE3"
  };

