export const setLocal = (key, object) =>{
      localStorage.setItem(key, JSON.stringify(object));
}

export const getLocalModel = (key) =>{
      let model = {};
      let data    = localStorage.getItem(key);
      if(data === null){
        return {};
      } else{
          model = JSON.parse(data);
      }
      return model;
}

export const getLocalList = (key) =>{
    let data    = localStorage.getItem(key);
    if(data === null){
      return [];
    } else
        return JSON.parse(data);
}


export const localKeys = {
  VIPANIM_ITEM_CATEGORY : "vipanim.seller.items.category",
  VIPANIM_USER_SELLER   : "vipanim.user",
  VIPANIM_USER_BUYER    : "vipanim.user",
  VIPANIM_MY_STORE      : "vipanim.seller.store"
}