import M from 'materialize-css';

export const createSelect = (elId, valueList, textList) =>{
    const el = document.getElementById(elId);
    for(let i = 0; i < valueList.length; i++){
        const option = document.createElement("option");
        option.setAttribute("value", valueList[i]);
        option.text = textList[i];
        el.appendChild(option);
    }
    M.AutoInit();
}


export const setSelectedValue = (elId, val) =>{
    const el = document.getElementById(elId);
    el.value = val;

    M.AutoInit();
}
