import { fsdb, docList, add2Fs, updateFs } from "../conf/fire";

export const updateItemImage = async (id, iLink) => {
    const cols = {
        image: iLink,
        updateOn: new Date().getTime()
    };

    return await updateFs(docList.STORE_ITEMS, id, cols);
}

export const updateItemById = async (id, item) => {
    const cols      = {
        itemName          : item.itemName,
        searchKey           : item.itemName.replace(/ /g, "").toLowerCase(),
        itemDescription   : item.itemDescription,
        mrp               : parseFloat(item.mrp),
        salePrice         : parseFloat(item.salePrice),
        shippingCharge    : parseFloat(item.shippingCharge),
        categoryId        : item.categoryId,
        categoryName      : item.categoryName,
        updateOn          : new Date().getTime()
    };

    console.log({update: cols});
    return await updateFs(docList.STORE_ITEMS, id, cols);
}

export const insertItem = async(data) =>{
    return await add2Fs(docList.STORE_ITEMS, data);
}