import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getOrderDetail } from "../db/db.order.load";
import { getOrderItems } from "../db/db.orderitem.load";
import { getOrderStatus } from "../models/status";


const OrderView = () =>{
    const {id} = useParams();
    const [master, setMaster] = useState({});
    const [items, setItems] = useState([]);

    const loadData = async () =>{
          let order = await getOrderDetail(id);
          let items = await getOrderItems(id);
          setMaster(order);
          setItems(items);

          console.log(order);
    }

    useEffect(() =>{
        loadData();
    }, []);

      return(<>
            <div className="row">
                <div className="col s12 divider"></div>
                <div className="col s12 m4">
                    <OStatus master={master} />
                    <BuyerDetail master={master} />
                    <ShippingAddr master={master} />
                </div>
                <div className="col s12 m8">
                    <div className="card">
                        <div className="card-content">
                            <table className="striped" style={{width : "100%"}}>
                                <thead>
                                    <tr style={{backgroundColor:"#ccceee"}}>
                                        <td>#</td>
                                        <td>ITEM NAME</td>
                                        <td className="right-align">QNTY</td>
                                        <td className="right-align">RATE</td>
                                        <td className="right-align">AMOUNT</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, idx) => <OItems key={item.id} index ={idx} item = {item} />)}
                                        <tr>
                                            <td colSpan="4" className="right-align"><strong>Item Total</strong></td>
                                            <td className="right-align"><strong>{Number.parseFloat(master.sumItemTotal).toFixed(2)}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4" className="right-align"><strong>Shipping Charge</strong></td>
                                            <td className="right-align"><strong>{Number.parseFloat(master.sumShippingCharge).toFixed(2)}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4" className="right-align"><strong>Grand Total</strong></td>
                                            <td className="right-align"><strong>{Number.parseFloat(master.sumTotalAmount).toFixed(2)}</strong></td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
      </>);
}

const BuyerDetail = ({master}) =>{
      return(<>
        <div className="card">
            <div className="card-content">
                <div className="row">
                    <div className="col s12"><span className="card-title">Buyer Detail</span></div>
                    <div className="col s12"><p>
                        <strong>{master.userName}</strong> <br />
                        <strong>{master.userPhone}</strong>
                        </p></div>
                </div>
            </div>
        </div>
      </>);
}

const ShippingAddr = ({master}) =>{
    return(<>
      <div className="card">
          <div className="card-content">
              <div className="row">
                  <div className="col s12"><span className="card-title">Shipping Address</span></div>
                  <div className="col s12"><p>
                      <strong>{master.shippingAddress}</strong> <br />
                      <strong>{master.altContactName}</strong>
                      <strong>{master.altContactName}</strong>
                      </p></div>
              </div>
          </div>
      </div>
    </>);
}

const OItems = ({item, index}) =>{
    return(<>
        <tr>
            <td>{index+1}</td>
            <td>{item.itemName}</td>
            <td>{item.qnty + " " + item.unit}</td>
            <td className="right-align">{Number.parseFloat(item.itemPrice).toFixed(2)}</td>
            <td className="right-align">{Number.parseFloat(item.itemTotal).toFixed(2)}</td>
        </tr>
    </>);
}

const OStatus = ({master})=>{
    const[status, setStatus] = useState(master.status);
    const[showReject, setShowReject] = useState(false);

    const divReject = (show)=>{
        setShowReject(show);
    }


    return(<>
    <div className="card yellow lighten-5">
        <div className="card-content">
            <div className="row">
                <div className="col s12"><span className="card-title">Order Status</span></div>
                <div className="col s12">
                    {getOrderStatus(status)}
                </div>
                <div className="col s6" style={{marginTop:"1.2em"}}>
                    <button 
                    className="btn btn-flat btn-block green white-text"
                    onClick={e=>divReject(true)}>ACCEPT ORDER</button>
                </div>
                <div className="col s6" style={{marginTop:"1.2em"}}>
                    <button className="btn btn-flat btn-block red white-text"
                            onClick={e=>divReject(true)}>REJECT ORDER</button>
                </div>
                <div className="col s12">
                     <div className="row"  style={{marginTop:"2em", display: showReject ? "block" : "none"}}>
                        <div className="col s12">
                        </div>
                        <div className="col s12 input-field">
                            <input type="text" id="tf_reject" />
                            <label htmlFor="tf_reject" className="active">Reason of rejection</label>
                        </div>
                        <div className="col s12">
                            <button className="btn btn-flat btn-small red lighten-4">REJECT</button>
                            &nbsp;<button className="btn btn-flat btn-small green lighten-4"
                            onClick={e=>divReject(false)}>CANCEL</button>
                        </div>
                     </div>
                </div>
                <div className="col s12" style={{marginTop:"2em"}}>
                    <div className="row">
                        <div className="col s12">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div></>)
}


export default OrderView;