import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createStore } from "redux";
import reduxList from "./utils/rdx/rdxlist";
import { Provider } from "react-redux";

const store = createStore(reduxList);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <React.StrictMode><App /></React.StrictMode>
    </Provider>
);



