import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "../pages/Home";
import ErrPage from "../global/ErrPage";
import SignIn from "../users/SignIn";
import ItemList from '../items/ItemList';
import SignOut from '../users/SignOut';
import ItemEdit from '../items/ItemEdit';
import OrderList from '../order/OrderList';
import OrderView from '../order/OrderView';
import ItemMaster from '../items/ItemMaster';

const Mains = () => {
    const logged = useSelector(state => state.isLogged);
    const dispatch = useDispatch();

    return (logged ? <Container /> : <Login />);
}


const Container = () => {
    return (<>
        <div className="container" style={{width:"95%"}}>
            <Router>
                <Routes>
                    <Route exact path="/"                       element={<Home />} />
                    <Route exact path="/page/home"              element={<Home />} />
                    <Route exact path="/user/login"             element={<SignIn />} />
                    <Route exact path="/user/logout"            element={<SignOut />} />
                    <Route exact path="/item/:add/save"         element={<ItemMaster />} />
                    <Route exact path="/item/edit/:itemId"      element={<ItemEdit />} />
                    <Route exact path="/item/list"              element={<ItemList />} />
                    <Route exact path="/order/a"                element={<OrderList />} />
                    <Route exact path="/order/p"                element={<OrderList />} />
                    <Route exact path="/order/:id/v"            element={<OrderView />} />
                    <Route path="*"                             element={<ErrPage />} />
                </Routes>
            </Router>
        </div>
    </>);
}

const Login = () =>{
    return(<>
     <div className = "container">
         <Router>
            <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="*" element={<SignIn />} />
            </Routes>
         </Router>
     </div>
     </>);
}

export default Mains;
