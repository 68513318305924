import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router } from 'react-router-dom';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import { LocalStorageKey } from './conf/localkeys';
import Header from "./global/Header";

import firebase from "firebase/compat/app";
import { firebaseConfig } from './conf/firebaseConfig';
import Mains from './global/Mains';
import M from 'materialize-css';
import './global/styles.css';


const App = () => {
  const app       = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
  const logged    = useSelector(state => state.isLogged);
  const dispatch  = useDispatch();

  const getUserLocalStore = () => {
    const arrUser = localStorage.getItem(LocalStorageKey.USER);
    if (arrUser) {
      dispatch({ type: "SIGN_IN" });
    } else {
      dispatch({ type: "SIGN_OUT" });
    }
  }

  useEffect(() => {
    M.AutoInit();
    getUserLocalStore();
  }, []);

  
  return (<>
      <Header />
      <Mains />
  </>);
}


export default App;
