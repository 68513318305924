import { Link } from 'react-router-dom';
import { LocalStorageKey } from '../conf/localkeys';
import { useState, useEffect } from "react";
import { getStoreDetail } from "../db/db.store.load";
import { getCatList } from '../db/db.cat.load';

const Dashboard = () => {
    const user = JSON.parse(localStorage.getItem(LocalStorageKey.USER));
    const [store, setStore] = useState(JSON.parse(localStorage.getItem(LocalStorageKey.CURRENTSTORE)));
    const [category, setCategory] = useState(JSON.parse(localStorage.getItem(LocalStorageKey.ITEMCATEGORY)));

    const checkStore = async () => {
        if (!store) {
            const newStore = await getStoreDetail(user.storeId);
            if (Object.keys(newStore).length > 0) {
                localStorage.setItem(LocalStorageKey.CURRENTSTORE, JSON.stringify(newStore));
                setStore(newStore);
            }
        }
    }

    const fetchItemCategory = async () =>{
        if(!category){
            const newCategory = await getCatList(store.id);
            if(Object.keys(newCategory).length > 0){
                localStorage.setItem(LocalStorageKey.ITEMCATEGORY, JSON.stringify(newCategory));
                setCategory(newCategory);
            }
        }
    }



    useEffect(() => {
        checkStore();
        fetchItemCategory();
    }, []);

    return (
        <>
            <div className="row">
                <div className="col s6 m4">
                    <div className="card">
                        <div className="card-content">
                            <span className="card-title">Orders</span>
                        </div>
                        <div className="card-action">
                            <Link to="/order/a">ALL</Link>
                            <Link to="/orders/p">PENDING</Link>
                        </div>
                    </div>
                </div>

                <div className="col s6 m4">
                <div className="card">
                        <div className="card-content">
                            <span className="card-title">Items</span>
                        </div>
                        <div className="card-action">
                            <Link to="/item/add">ADD</Link>
                            <Link to="/item/list">LIST</Link>
                        </div>
                    </div>
                </div>

                <div className="col s6 m4">
                <div className="card">
                        <div className="card-content">
                            <span className="card-title">Categories</span>
                        </div>
                        <div className="card-action">
                            <Link to="/items/add">ADD</Link>
                            <Link to="/items/list">LIST</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;