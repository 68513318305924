const cartItems = (count = [], action) => {
    switch (action.type) {
        case "CART_COUNT"  :
            return getCartCount();
    }
    return [];
}

const getCartCount = ()=>{
    const storedItem = localStorage.getItem("cartItems");
    if(storedItem !==null){
         return JSON.parse(storedItem);
    }
    return [];
}



export default cartItems;