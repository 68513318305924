import { fsdb , docList, getModelById} from "../conf/fire";

export const getItemByStoreId = async (storeid, updateOn) => {
    const list  = [];
    const db    = fsdb();
    const snap  = await db.collection(docList.STORE_ITEMS)
                    .where("storeId", "==", storeid)
                    .where("updateOn", "<", updateOn)
                    .orderBy("updateOn", "desc").limit(20)
                    .get();
    snap.forEach(doc => {
        let i = doc.data(); i.id = doc.id;
        list.push(i);
    });

    return list;
}

export const getItemById = async (id) => {
    return await getModelById(docList.STORE_ITEMS, id);
}

