import { docList, fsdb } from "../conf/fire"

export const getOrderItems = async (mId) =>{
    const items = [];
    const db = fsdb();
    const snap = await db.collection(docList.ORDER_ITEMS).where("masterId", "==", mId).get();

    snap.forEach(docs => {
        let item = docs.data();
        item.id = docs.id;
        items.push(item);
      });

      return items;
}