import React, { useEffect, useState } from 'react';
import { getLocalCategories } from '../db/db.cat.load';
import { createSelect, setSelectedValue } from '../utils/ui/select.hendelar';
import { useParams } from 'react-router';
import { storeItems } from '../models/StoreItems';
import { insertItem, updateItemById, updateItemImage } from '../db/db.item.save';
import { getItemById } from '../db/db.item.load';
import { getStoraze } from '../conf/fire';
import { fileUpload, getCroppedImg } from '../utils/xtra/file.upload';
import Cropper from 'react-easy-crop';

const ItemMaster = () => {
    const { add }                           = useParams();
    const [headline, setHeadline]           = useState("Add Item");
    const [item, setItem]                   = useState(storeItems);
    const [itemName, setItemName]           = useState("");
    const [itemDesc, setItemDesc]           = useState("");
    const [catList, setCatList]             = useState([]);
    const [cat, setCat]                     = useState({});
    const [itemMrp, setItemMrp]             = useState("0");
    const [itemSalePrice, setItemSalePrice] = useState("0");
    const [inProgress, setInProgress]       = useState(false);
    
    const loadCategories = async () =>{
        let cList = await getLocalCategories();
        const keys = [];
        const vals = [];

        cList.forEach(cat => {
             keys.push(cat.id);
             vals.push(cat.categoryName);
        });

        setCatList(cList);
        createSelect("catOption", keys, vals);
    }

    const load4Edit = async() =>{
        if(add == "new"){return;}
        let model = await getItemById(add);
        if(Object.keys(model).length === 0){
          return;
        }
        console.log(model);

        setItem(model);
        setItemName(model.itemName);
        setItemDesc(model.itemDescription);
        setItemMrp (model.mrp);
        setItemSalePrice(model.salePrice);
        setSelectedValue("catOption", model.categoryId);
    }

    const setCategory = (val) =>{
         catList.forEach(cat =>{
              if(cat.id === val){
                setCat(cat);
              }
         });
    }

    const btnSave = async () =>{
        setInProgress(true);
        item.itemName         = itemName;
        item.itemDescription  = itemDesc;
        item.mrp              = parseFloat(itemMrp);
        item.salePrice        = parseFloat(itemSalePrice);
        item.categoryId       = cat.id;
        item.categoryName     = cat.categoryName;
        item.updateOn         = Date.now();

        console.log(item);
         if(add == "new"){
            const id = await insertItem(item);
         }else{
             const id = await updateItemById(add, item);
         }         
    }

    useEffect(()=>{
        if(add != "new"){setHeadline("UPDATE ITEM")}
        loadCategories();
        load4Edit();
    }, []);

    return (<>
        <div className='row'>
        <div className="col s12 m7 offset-m1">
          <div className='card'>
            <div className='card-content'>
              <h5 className="headline1">{headline}</h5>
              <div className='row'>
                <div className='input-field col s12'>
                  <input type='text' value={itemName} onChange={(e) => setItemName(e.target.value)} />
                  <label className='active'>Item Name</label>
                </div>
                <div className='input-field col s12'>
                  <input type='text' value={itemDesc} onChange={(e) => setItemDesc(e.target.value)} />
                  <label className='active'>Description</label>
                </div>
                <div className="input-field col s6">
                  <select id="catOption" onChange={(e) => setCategory(e.target.value)}>
                    <option value="" selected disabled>Choose Category</option>
                  </select>
                </div>
                <div className='input-field col s3'>
                  <input type='text' value={itemMrp} onChange={(e) => setItemMrp(e.target.value)} />
                  <label className='active'>MRP</label>
                </div>
                <div className='input-field col s3'>
                  <input type='text' id="itemSaleP" value={itemSalePrice} onChange={(e) => setItemSalePrice(e.target.value)} />
                  <label htmlFor="itemSaleP" className="active">Sale Price</label>
                </div>
                <div className='col s12'>
                  <button className='btn green btn-flat white-text btn-small' onClick={()=> btnSave()} disabled={inProgress}>SAVE</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col s12 m3">
          {Object.keys(item).length > 0 ? <><ItemImage item={item} /></> : <></>}
        </div>

      </div>

    </>)
}



const ItemImage = ({ item }) => {
  const storage = getStoraze();
  const [viewCanvas, setViewCanvas] = useState(false);
  const [uploadableFile, setUploadableFile] = useState(null);
  const [imagePreview, setImagePreview]     = useState(item.image);
 
  const loadImg = async (e) => {
        setImagePreview   (URL.createObjectURL(e.target.files[0]));
        setUploadableFile (e.target.files[0]);
        const preview   = await getCroppedImg(URL.createObjectURL(e.target.files[0]));

        console.log({imageurl : preview});
        setImagePreview(preview);
        setViewCanvas(true);
        setCanvas(URL.createObjectURL(e.target.files[0]));
  }

  const setCanvas = (imgUrl) =>{
       const canvas = document.querySelector("#canvas");
       const ctx    = canvas.getContext("2d");
       canvas.width   = 300;
       canvas.height  = 300;
       const img    = new Image();
       img.onload = () =>{
          let maxSize     = Math.max(canvas.width/img.width, canvas.height/img.height);
          let newWidth    = img.width   * maxSize;
          let newHeight   = img.height  * maxSize;
          let x = (canvas.width/2) - (newWidth/2);
          let y = (canvas.height/2) - (newHeight/2);
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, x, y, newWidth, newHeight);
       }
       img.src      = imgUrl;
  }
  

  const uploadFile = async () =>{
      const image   = await getCroppedImg(imagePreview);
      const link  = await fileUpload(uploadableFile, "images/items/"+item.id+".jpg");
      console.log(link);
      await updateItemImage(item.id, link);
  }

  return (<>
    <div className="row">
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="row">

                <div className="col s12 center" style={{display : viewCanvas ? "block" : "none"}}>
                   <canvas id="canvas" style={{border: "1px solid black", width : "300px", height: "300px"}} />
                </div>
              
                <div className="col s12 center" style={{display : viewCanvas ? "none" : "block"}}>
                  <img src={imagePreview} className="responsive-img" />
                </div>

              <div className="col s12">
                <div className="file-field input-field">
                    <div className="btn btn-flat btn-small green white-text">
                      <span>UPLOAD FILE</span>
                      <input type="file" onChange={e=>loadImg(e)} />
                    </div>
                    <div className="file-path-wrapper">
                    <input className="file-path validate" type="text" />
                  </div>
                </div>
              </div>
              <div className="col s12">
                <button className="btn btn-flat grey white-text" onClick={(e)=>uploadFile()}>UPLOAD IMAGE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}



export default ItemMaster


