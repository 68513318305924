import { useEffect, useState } from "react";
import { getLatestOrders } from "../db/db.order.load";
import { LocalStorageKey } from "../conf/localkeys";
import { getStrDate } from "../utils/xtra/dates";
import { getOrderStatus } from "../models/status";
import { Link } from "react-router-dom";

const OrderList = () =>{
    const[oMaster, setOMaster]  = useState([]);
    const[time, setTime]        = useState(Date.now());

    const loadOrders = async () =>{
        let s      = JSON.parse(localStorage.getItem(LocalStorageKey.CURRENTSTORE));
        let orders = await getLatestOrders(time, s.id);
        setOMaster(orders);

        console.log(orders);
    }

    useEffect(()=>{
        loadOrders();
    }, []);

    return(<>
        <div className="row">
            <div className="col s12">
                <table className="striped highlight">
                    <thead>
                        <tr>
                            <td>Date</td>
                            <td>Name</td>
                            <td>Phone No</td>
                            <td><span className="right">Item Count</span></td>
                            <td><span className="right">Status</span></td>
                            <td><span className="right">Order Value</span></td>
                            <td>View</td>
                        </tr>
                    </thead>
                    <tbody>
                        {oMaster.map((master => <Master key={master.id} mst = {master} />))}
                    </tbody>
                </table>
            </div>
        </div>
    </>);
}

const Master = ({mst}) =>{
    return(<>
        <tr>
            <td>{getStrDate(mst.placedOn)}</td>
            <td>{mst.userName}</td>
            <td>{mst.userPhone}</td>
            <td><span className="right">{mst.itemCount > 1 ? mst.itemCount + " Items" : mst.itemCount + " Item"}</span></td>
            <td><span className="right">{getOrderStatus(mst.status)}</span></td>
            <td><span className="right">{mst.sumTotalAmount}</span></td>
            <td><Link to={"/order/"+mst.id+"/v"}>VIEW</Link></td>
        </tr>
    </>);
}


export default OrderList;